.Special-card-wrapper{
    background-color: white;
    padding: 14px 0px;
    .card-content-wrapper{
        display: flex;
        .text{
            margin-left: 30px;
        }
        .heading{
            font-size: 1.5em;
        }
    }
}

.Special-wrapper{
    padding: 5% 0px;
    .heading{
        font-size: 2em;
        font-weight: 600;
        color: #2c345c;
    }
    .row{
        justify-content: space-between;
        margin: 5% 15px
    }
}