.plan-wrapper{
    padding: 5% 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;


    .image-container{
        display: flex;
        align-items: flex-end;

        img{
            width: 25vw;
        }

        @media only screen and (max-width: 575px){
            display: none;
        }

    }
    .plan-container{
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading-main{
            font-size: 3em;
            font-weight: 600;
            margin-bottom: 60px;
            color: #2c345c;

            @media only screen and (max-width: 575px){
                font-size: 2em;
            }
        }

        .cards-container{
            display: flex;
            align-items: center;

            @media only screen and (max-width: 575px){
                flex-direction: column;
            }
            .card-in-plan{
                background-color: white;
                height: 45vh;
                min-height: 357px;
                width: 20vw;
                text-align: center;
                border: 2px solid #B6D0EB;
                border-radius: 13px;

                @media only screen and (max-width: 575px){
                    width: 60vw;
                    height: 45vh;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                @media only screen and (max-width: 1000px) and (min-width: 576px){
                    width: 25vw;
                    height: 45vh;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
    
                .top{
                    height: 20%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 1vh 0px;
                    border-bottom: 2px dashed #B6D0EB;

                    .heading{
                        font-size: 1.5em;
                        font-weight: 600;
                    }

                    .price{
                        font-size: 1em;
                        display: flex;
                        justify-content: center;
                        align-items:baseline;
                        color: #3371A2;
                        .number{
                            font-size: 1.5em;
                        }
                    }
                }
                .center{
                    height: 60%;
                    div{
                        font-size: 1.1em;
                        font-weight: 600;
                        color: #2c345c;
                    }

                    .plus{
                        font-size: 1.4em;
                        color: #3371A2;
                    }
                }
                .bottom{
                    height: 20%;
                    a{
                        display: flex;
                        justify-content: center;
                        text-decoration: none;
                        .button{
                            width: 150px;
                            padding: 5px 5px;
                            border: 2px solid #3371A2;
                            border-radius: 23px;
                            font-size: 1.2em;
                            font-weight: 600;
                            color: #2c345c;
                            transition: all 0.4s ease;
                            &:hover{
                                background-color: #3371A2;
                                color: white;
                            }
                        }
                    }
                    a.disabled {
                        .button{
                            color: rgba(128, 128, 128, 0.514);
                            background: rgb(175, 175, 175);
                            border: none;
                        }
                    }

                }

                .center, .bottom{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    
                }
    
            }

            .card-middle{
                border:none;
                height: 48vh;
                min-height: 414px;
                width: 23vw;
                margin: 0px -15px;
                z-index: 1;

                -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);

                @media only screen and (max-width: 575px){
                    margin: 0px;
                    width: 60vw;
                    height: 45vh;
                    box-shadow: none;
                    border: 2px solid #F26f72;
                }

                @media only screen and (max-width: 1000px) and (min-width: 576px){
                    width: 25vw;
                    height: 45vh;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .price{
                    color: #F26f72 !important;
                    font-size: 22px !important;
                }

                .bottom{
                    .button{
                        background-color: #F26f72;
                        color: white;
                        border:2px solid #F26f72;

                        &:hover{
                            background-color: white;
                            color: #F26f72;
                            border:2px solid #F26f72;
                        }
                    }

                    a.disabled {
                        .button{
                            cursor: not-allowed;
                            opacity: 0.5;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .background{
        position: absolute;
        z-index: -1;
    }

    .one{
        left: 0;
        width: 59vw;
        bottom: 0;
    }
    .two{
        bottom: 0;
        right: 6vw;
        width: 59vw;
    }
}