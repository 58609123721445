.intro-wrapper{
    position: relative;
    .intro-content{
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0 !important;
        padding: 10vh 0;

        @media only screen and (max-width: 1160px) and (min-width: 651px){
            padding: 2vh 0;
        }
        @media only screen and (max-width: 650px){
            padding-top: 10vh;
    
        }
    
        .right, .left{
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }
        
        .left{
            .heading{
                font-size: 4em;
                font-weight: 600;
                color: #3371A2;
                @media only screen and (max-width: 575px){
                    font-size: 3em;
                }
            }
            .intro-description{
                font-size: 1.5em;
            }

            .button-container{
                display: flex;
                width: 100%;

                .button-right{
                    margin-left: 30px;
                }

            }

            @media only screen and (max-width: 1000px) and (min-width: 771px){
                .heading{
                    font-size: 2.5em;
                    }
                .intro-description{
                    font-size: 1.3em;
                }
            }

            @media only screen and (max-width: 770px) {
                .heading{
                    font-size: 2.3em;
                    }
                .intro-description{
                    font-size: 1em;
                }
            }
        }
        
        .right{
            align-items: flex-end !important;
            img{
                width: 24vw;

                @media only screen and (max-width: 575px){
                    display: none;
                }
            }
        }
    
    }
    .bg{
        position: absolute;
        z-index: -1;
    }
    .background1{
        height: 100%;
        right: 0;
        top: 0;
    }
    .background2{
        height: 5vw;
        right: 10vw;
        top: 0;
    }
    .background3{
        height: 9vw;
        bottom: 0;
        right: 2vw;
    }

    @media only screen and (max-width: 770px){
        .background1{
            display: none;
        }
    }
}
