.header-wrapper{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 5;
    @media only screen and (max-width: 650px){
        display: none;

    }

    .nav-menu, .logo-container{
        display: flex;
        align-items: center;
        .nav-item{
            padding: 2vh 2vw;
            text-decoration: none;
            font-size: 1em;
            font-weight: 600;
            color: #2c345c;
            @media only screen and (max-width: 575px){
                padding: 1vh 1vw;
            }

            &:hover{
                color: #F26f72;
            }
        }

        .disabled {
            color: #232733;
            cursor: not-allowed;
            opacity: 0.5;
            text-decoration: none;
        }

        .logo-name{
            margin:0px 20px;
            font-size: 1.3em;
            font-weight: 600;
            color: #3371A2;
        }

        .remotrix-logo{
            width: 21px;
        }
    }
    
}