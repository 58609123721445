.button-container{
    a{
        text-decoration: none;
    }
    .btn-shape{
        width: 250px;
        height: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 13px;
        text-align: center;
        .top-text{
            font-size: 1.3em;
            font-weight: 600;
        }

        @media only screen and (max-width: 575px){
            width: 30vw;
            height: 6vh;
            font-size: 0.6em;
        }

        @media only screen and (max-width: 770px) and (min-width: 576px){
            width: 23vw;
            height: 7vh;
            font-size: 0.8em;
        }
    }

    .download-btn{
        color: white;
        background-color: #2e9429;
        border:2px solid #2e9429;
        transition: all 0.4s ease;
        &:hover{
            background-color: #34b42d;
        }

    }

    .buy-btn{
        border:2px solid #F26f72;
        background-color: white;
        color: black;
        transition: all 0.4s ease;
        &:hover{
            color: white;
            background-color: #F26f72;
        }
    }
}

.disabled{
    cursor: not-allowed;
    pointer-events: none;
}